/* This example requires Tailwind CSS v2.0+ */

import { FaPython } from 'react-icons/fa';
import { 
  SiTypescript,
  SiDocker,
  SiSupabase,
  SiPowerbi,
  // SiHuggingface,
  SiPytorch,
  SiTensorflow,
  SiKeras,
  SiMicrosoftazure,
  // SiOpenai,
  // SiLangchain
} from 'react-icons/si';
import { CgCloud } from "react-icons/cg";
import { Fade } from "react-reveal";
import effectLogo from "../img/Effect.svg";
import convexLogo from "../img/convex-logo-only.svg";
import hfLogo from "../img/hf-logo.svg";

const skills = [
  {
    name: 'Transformers',
    icon: <img src={hfLogo} className="w-full h-full" alt="Hugging Face" />,
    category: 'ML'
  },
  {
    name: 'PyTorch',
    icon: <SiPytorch />,
    category: 'ML'
  },
  {
    name: 'TensorFlow',
    icon: <SiTensorflow />,
    category: 'ML'
  },
  {
    name: 'TypeScript',
    icon: <SiTypescript />,
    category: 'Dev'
  },
  {
    name: 'Docker',
    icon: <SiDocker />,
    category: 'Dev'
  },
  {
    name: 'Effect',
    icon: <img src={effectLogo} className="w-full h-full" alt="Effect" />,
    category: 'Dev'
  },
  {
    name: 'Convex',
    icon: <img src={convexLogo} className="w-full h-full" alt="Convex" />,
    category: 'Infra'
  },
  {
    name: 'Supabase',
    icon: <SiSupabase />,
    category: 'Infra'
  },
  {
    name: 'Azure',
    icon: <SiMicrosoftazure />,
    category: 'Infra'
  },
  {
    name: 'Power BI',
    icon: <SiPowerbi />,
    category: 'Infra'
  }
];

export default function Skills() {
  return (
    <div className="relative h-max font-sans bg-gradient-to-b from-white to-gray-50/30" id="skills">
      <main className="max-w-7xl mx-auto mb-24">
        {/* <Fade left>
          <div className="flex items-center bg-blue-100 w-max h-7 px-3 rounded-lg mb-5">
            <p className="font-normal text-sm text-blue-700">MY SKILLS</p>
          </div>
        </Fade> */}
        
        <Fade left>
          <h1 className="text-3xl font-bold mb-6">
            <span className="inline-block tracking-wide bg-gradient-to-r from-gray-900 via-blue-800 to-gray-900 bg-clip-text text-transparent">
              Tech Stack
            </span>
          </h1>
          <p className="max-w-lg text-lg text-gray-600 leading-relaxed mb-12">
            Just some of the tools and technologies I work with.
          </p>
        </Fade>
        <div className="relative">
          {/* Background Pattern */}
          <div className="absolute inset-0 bg-[radial-gradient(#3b82f620_1px,transparent_1px)] [background-size:16px_16px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,black,transparent)]" />
          
          <div className="relative grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
            {skills.map((item, index) => (
              <Fade bottom delay={index * 50} key={item.name}>
                <div className="group relative bg-white rounded-xl border border-gray-100 hover:border-blue-200 shadow-sm hover:shadow-md transition-all duration-300 hover:-translate-y-1 overflow-hidden">
                  {/* Category Indicator */}
                  <div className={`absolute top-0 left-0 w-1 h-full transition-colors duration-300
                    ${item.category === 'ML' ? 'bg-blue-400/20 group-hover:bg-blue-400' : 
                      item.category === 'Dev' ? 'bg-purple-400/20 group-hover:bg-purple-400' : 
                      'bg-green-400/20 group-hover:bg-green-400'}`} 
                  />
                  
                  <div className="flex items-center p-3 pl-5">
                    {/* Icon */}
                    <div className="relative w-8 h-8 flex items-center justify-center">
                      {typeof item.icon === 'object' && item.icon.type === 'img' ? (
                        <div className="w-full h-full transform transition-transform duration-300 group-hover:scale-110">
                          {item.icon}
                        </div>
                      ) : (
                        <div className="text-xl text-blue-600/90 transform transition-transform duration-300 group-hover:scale-110">
                          {item.icon}
                        </div>
                      )}
                    </div>

                    {/* Name */}
                    <p className="ml-3 text-sm font-medium text-gray-700 group-hover:text-blue-600 transition-colors duration-300">
                      {item.name}
                    </p>
                  </div>
                </div>
              </Fade>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
}

import { useContext, useMemo, useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import { GithubRepos } from '../App';

const EXCLUDED_LANGUAGES = new Set(['Jupyter Notebook', 'CSS', 'HTML', "SQL", 'sql']);

const PROGRAMMING_LANGUAGES = new Set([
  'Python',
  'TypeScript',
  'JavaScript',
  'Java',
  'C++',
  'C#',
  'Go',
  'Rust',
  'PHP',
  'Ruby',
  'Swift',
  'Kotlin',
  'R',
  'SQL',
  'Scala',
  'Dart',
  'Vue'
]);

// Function to fetch lines of code from codetabs API with delay
async function fetchRepoLinesOfCode(owner, repo) {
  try {
    const response = await fetch(`https://api.codetabs.com/v1/loc?github=${owner}/${repo}`);
    if (!response.ok) {
      if (response.status === 429) {  // Too many requests
        await new Promise(resolve => setTimeout(resolve, 5000));  // Wait 5 seconds
        return fetchRepoLinesOfCode(owner, repo);  // Retry
      }
      throw new Error(`Failed to fetch LOC: ${response.status}`);
    }
    const data = await response.json();
    return data.reduce((acc, entry) => {
      if (entry.language === 'Total') {
        acc.total = entry.linesOfCode;
      } else {
        acc.languages = {
          ...acc.languages,
          [entry.language]: entry.linesOfCode
        };
      }
      return acc;
    }, { total: 0, languages: {} });
  } catch (error) {
    console.error(`Error fetching LOC for ${owner}/${repo}:`, error);
    return { total: 0, languages: {} };
  }
}

const GITHUB_DATA_QUERY = gql`
  query {
    viewer {
      repositories(first: 100, ownerAffiliations: [OWNER, ORGANIZATION_MEMBER]) {
        nodes {
          name
          owner {
            login
          }
          description
          updatedAt
          stargazerCount
          forkCount
          isPrivate
          languages(first: 10, orderBy: {field: SIZE, direction: DESC}) {
            totalSize
            totalCount
            edges {
              size
              node {
                name
                color
              }
            }
          }
          object(expression: "HEAD") {
            ... on Commit {
              history {
                totalCount
              }
            }
          }
          stats: object(expression: "HEAD") {
            ... on Commit {
              additions
              deletions
            }
          }
          repositoryTopics(first: 10) {
            nodes {
              topic {
                name
              }
            }
          }
          url
          homepageUrl
        }
      }
      contributionsCollection {
        commitContributionsByRepository(maxRepositories: 100) {
          repository {
            name
            owner {
              login
            }
            isPrivate
            description
            updatedAt
            languages(first: 10, orderBy: {field: SIZE, direction: DESC}) {
              totalSize
              totalCount
              edges {
                size
                node {
                  name
                  color
                }
              }
            }
            object(expression: "HEAD") {
              ... on Commit {
                history {
                  totalCount
                }
              }
            }
          }
          contributions {
            totalCount
          }
        }
      }
    }
  }
`;

export function useGitHubData() {
  const repos = useContext(GithubRepos);
  const { data: contributionsData, loading, error } = useQuery(GITHUB_DATA_QUERY);

  return useMemo(() => {
    if (!repos || !contributionsData?.viewer || loading || error) {
      return {
        personalProjects: [],
        stats: {
          personal: 0,
          external: 0,
          totalLines: 0,
          languageDistribution: {},
          primaryLanguage: null,
          totalRepositories: 0
        },
        chartData: {
          languages: [],
          repositories: []
        }
      };
    }

    const blacklisted = ['NicolaiHerforth.github.io'];
    const personalRepos = contributionsData.viewer.repositories.nodes
      .filter(r => !blacklisted.includes(r.name) && r.owner.login === 'NicolaiHerforth');

    // Calculate language distribution and total lines
    const languageStats = {};
    let totalLines = 0;

    // Process personal repositories languages
    personalRepos.forEach(repo => {
      const languages = repo.languages?.edges || [];
      
      languages.forEach(({ size, node }) => {
        if (!PROGRAMMING_LANGUAGES.has(node.name) || EXCLUDED_LANGUAGES.has(node.name)) return;
        languageStats[node.name] = {
          size: (languageStats[node.name]?.size || 0) + size,
          color: node.color || '#6b7280'
        };
        totalLines += size;
      });
    });

    // Calculate percentages and find primary language
    const languageDistribution = {};
    let primaryLanguage = null;
    let maxSize = 0;

    Object.entries(languageStats).forEach(([language, { size }]) => {
      const percentage = (size / totalLines) * 100;
      languageDistribution[language] = percentage;
      if (size > maxSize) {
        maxSize = size;
        primaryLanguage = language;
      }
    });

    // Format data for charts
    const languages = Object.entries(languageStats)
      .map(([name, { size, color }]) => ({
        name,
        percentage: Math.round((size / totalLines) * 100 * 100) / 100,
        color: color
      }))
      .sort((a, b) => b.percentage - a.percentage);

    // Format repository data for the bar chart
    const repositories = personalRepos
      .map(repo => {
        // Get total size excluding unwanted languages
        const totalSize = (repo.languages?.edges || [])
          .filter(({ node }) => !EXCLUDED_LANGUAGES.has(node.name))
          .reduce((acc, { size }) => acc + size, 0);
          
        return {
          name: repo.name,
          lines: totalSize
        };
      })
      .filter(repo => repo.lines > 0)
      .sort((a, b) => b.lines - a.lines)
      .slice(0, 5);

    // Format personal projects for cards
    const formattedPersonalProjects = personalRepos.map(repo => ({
      name: repo.name,
      owner: repo.owner?.login,
      description: repo.description,
      language: repo.languages?.edges[0]?.node.name,
      stargazers_count: repo.stargazerCount,
      forks_count: repo.forkCount,
      updated_at: repo.updatedAt,
      html_url: repo.url,
      homepage: repo.homepageUrl,
      topics: repo.repositoryTopics?.nodes?.map(n => n.topic.name) || []
    }));

    return {
      personalProjects: formattedPersonalProjects,
      stats: {
        personal: formattedPersonalProjects.length,
        external: contributionsData.viewer.contributionsCollection?.commitContributionsByRepository?.length || 0,
        totalLines,
        languageDistribution,
        primaryLanguage,
        totalRepositories: formattedPersonalProjects.length + (contributionsData.viewer.contributionsCollection?.commitContributionsByRepository?.length || 0)
      },
      chartData: {
        languages,
        repositories
      }
    };
  }, [repos, contributionsData, loading, error]);
} 
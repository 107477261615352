import { Fade } from "react-reveal";
import { useGitHubData } from "../hooks/useGitHubData";
import { formatNumber } from "../utils/formatNumber";

export default function Stats() {
  const { stats } = useGitHubData();

  return (
    <div className="relative h-max font-sans mb-10" id="stats">
      <main className="relative flex flex-col md:flex-row">
        <div className="mx-auto max-w-screen-2xl w-full text-left lg:text-left z-20">
          <Fade left>
            <div className="lg:w-4/5 xl:pr-16">
            <Fade left>
              <div className="flex items-center bg-blue-100 w-max h-7 px-3 rounded-lg mb-5 lg:m-0 lg:mb-10 sm:mb-10">
                <p className="font-normal text-sm text-blue-700">STATISTICS</p>
              </div>
            </Fade>
              <h1 className="text-4xl font-bold mb-6">
                <span className="block xl:inline tracking-wide bg-gradient-to-r from-blue-900 to-gray-900 bg-clip-text text-transparent">
                  Development Impact
                </span>
              </h1>
              <p className="max-w-lg text-lg text-gray-600 leading-relaxed">
                Having fun with <span className="font-semibold text-blue-800">{stats.personal} personal projects</span> while 
                actively contributing to <span className="font-semibold text-blue-800">{stats.external} external repositories</span>.
              </p>
            </div>
          </Fade>
        </div>
      </main>
    </div>
  );
}

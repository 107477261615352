import { Fade } from "react-reveal";
import { useGitHubData } from "../hooks/useGitHubData";
import { formatNumber } from "../utils/formatNumber";
import { FiGithub, FiCode, FiDatabase } from "react-icons/fi";
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';

export default function Charts() {
  const { stats, chartData } = useGitHubData();

  const languageColors = {
    TypeScript: '#3178C6',
    Vue: '#60A5FA',
    JavaScript: '#F7DF1E',
    Java: '#E76F00',
    'C++': '#00599C',
    'C#': '#3ECF8E',
    Go: '#00ADD8',
    Rust: '#FF4154',
    PHP: '#8892BF',
    Ruby: '#CC342D',
    Swift: '#F05138',
    Kotlin: '#7F52FF',
    R: '#276DC3',
    SQL: '#FFB900',
    Scala: '#DC322F',
    Dart: '#0175C2',
    Python: '#42B883'
  };

  const getLanguageColor = (name, defaultColor) => languageColors[name] || defaultColor;
  const getHoverColor = (color) => {
    // Brighten the color slightly for hover
    return color.startsWith('#') 
      ? color.replace(/[0-9a-f]{2}(?=[0-9a-f]{0,2}$)/i, m => {
          const n = Math.min(255, parseInt(m, 16) + 25).toString(16);
          return n.length === 1 ? '0' + n : n;
        })
      : color;
  };

  const chartOptions = {
    cutout: '60%',
    radius: '90%',
    plugins: {
      legend: { display: false },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        titleFont: { size: 13, weight: 'bold' },
        bodyFont: { size: 12 },
        padding: 12,
        displayColors: true,
        callbacks: {
          label: function(context) {
            const lines = Math.round(context.raw * stats.totalLines / 100);
            return [
              `${context.raw}% of codebase`,
              `${formatNumber(lines)} lines`
            ];
          }
        }
      }
    },
    animation: {
      animateRotate: true,
      animateScale: true,
      duration: 1000,
      easing: 'easeOutQuart'
    },
    maintainAspectRatio: false,
    responsive: true
  };

  const chartColors = chartData.languages.map(l => getLanguageColor(l.name, l.color));
  const chartHoverColors = chartColors.map(getHoverColor);

  return (
    <div className="relative h-max font-sans mb-14" id="charts">
      <main className="relative flex flex-col">
        <div className="mx-auto max-w-screen-2xl w-full text-left lg:text-left z-20">
          <Fade bottom>
            {/* KPI Cards */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
              <div className="bg-white rounded-2xl p-6 shadow-lg border border-gray-100">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-500 mb-1">Total Repositories</p>
                    <h3 className="text-2xl font-bold text-gray-900">{stats.totalRepositories}</h3>
                  </div>
                  <div className="p-3 bg-blue-50 rounded-xl">
                    <FiGithub className="w-6 h-6 text-blue-600" />
                  </div>
                </div>
                <p className="text-xs text-gray-400 mt-2">Active Development</p>
              </div>

              <div className="bg-white rounded-2xl p-6 shadow-lg border border-gray-100">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-500 mb-1">Lines of Code</p>
                    <h3 className="text-2xl font-bold text-gray-900">{formatNumber(stats.totalLines)}</h3>
                  </div>
                  <div className="p-3 bg-purple-50 rounded-xl">
                    <FiCode className="w-6 h-6 text-purple-600" />
                  </div>
                </div>
                <p className="text-xs text-gray-400 mt-2">Across {Object.keys(stats.languageDistribution).length} Languages</p>
              </div>

              <div className="bg-white rounded-2xl p-6 shadow-lg border border-gray-100">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-sm text-gray-500 mb-1">Primary Language</p>
                    <h3 className="text-2xl font-bold text-gray-900">{stats.primaryLanguage}</h3>
                  </div>
                  <div className="p-3 bg-green-50 rounded-xl">
                    <FiDatabase className="w-6 h-6 text-green-600" />
                  </div>
                </div>
                <p className="text-xs text-gray-400 mt-2">Most Used Technology</p>
              </div>
            </div>

            {/* Charts Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Language Distribution */}
              <div className="bg-white rounded-2xl p-6 shadow-lg border border-gray-100">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Programming Language Distribution</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="relative h-[250px]">
                    <Doughnut
                      data={{
                        labels: chartData.languages.map(l => l.name),
                        datasets: [{
                          data: chartData.languages.map(l => l.percentage),
                          backgroundColor: chartColors,
                          borderColor: '#ffffff',
                          borderWidth: 3,
                          hoverBackgroundColor: chartHoverColors,
                          hoverBorderColor: '#ffffff',
                          hoverBorderWidth: 4,
                          hoverOffset: 12,
                          offset: 4
                        }]
                      }}
                      options={chartOptions}
                    />
                  </div>
                  <div className="space-y-3">
                    {chartData.languages.map(({ name, percentage, color }) => {
                      const baseColor = getLanguageColor(name, color);
                      return (
                        <div key={name} className="group cursor-pointer hover:bg-gray-50/80 rounded-lg p-2 transition-all duration-300">
                          <div className="flex justify-between text-sm mb-1">
                            <span className="text-gray-600 group-hover:text-gray-900 font-medium flex items-center gap-2">
                              <div 
                                className="w-3 h-3 rounded-full shadow-sm transition-transform duration-300 group-hover:scale-125" 
                                style={{ 
                                  backgroundColor: baseColor,
                                  boxShadow: `0 0 10px ${baseColor}40`
                                }} 
                              />
                              {name}
                            </span>
                            <span className="text-gray-900 font-medium">{percentage}%</span>
                          </div>
                          <div className="w-full bg-gray-100 rounded-full h-1.5 overflow-hidden">
                            <div
                              className="h-1.5 rounded-full transition-all duration-500 group-hover:opacity-100"
                              style={{
                                width: `${percentage}%`,
                                background: `linear-gradient(90deg, ${baseColor}aa, ${baseColor}ff)`,
                                boxShadow: `0 0 8px ${baseColor}40`
                              }}
                            />
                          </div>
                          <div className="text-xs text-gray-500 mt-1 opacity-0 group-hover:opacity-100 transition-all">
                            {formatNumber(Math.round(percentage * stats.totalLines / 100))} lines
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              {/* Repository Size Distribution */}
              <div className="bg-white rounded-2xl p-6 shadow-lg border border-gray-100">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Personal Repository Trends</h3>
                <div className="space-y-4">
                  {chartData.repositories.map(({ name, lines }) => (
                    <div key={name}>
                      <div className="flex justify-between text-sm mb-1">
                        <span className="text-gray-600">{name}</span>
                        <span className="text-gray-900 font-medium">{formatNumber(lines)} lines</span>
                      </div>
                      <div className="w-full bg-gray-100 rounded-full h-2">
                        <div
                          className="h-2 rounded-full bg-blue-500 transition-all duration-500"
                          style={{
                            width: `${(lines / chartData.repositories[0].lines) * 100}%`
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="text-xs text-gray-400 mt-4 italic">
                  Includes both public and private repositories
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </main>
    </div>
  );
}

/* This example requires Tailwind CSS v2.0+ */

import AnchorLink from 'react-anchor-link-smooth-scroll'
import { socialLinks } from "../data/socialLinks";  
import { FaEnvelope } from "react-icons/fa";
import { Fade } from "react-reveal";



export default function Footer() {
  
  return (
    <div className="my-10 flex flex-col ">
      <Fade bottom>
      <div className="bg-contactbg rounded-lg p-10 relative h-max font-sans mb-10" id="stats">
        <main className="relative h- flex flex-col md:flex-row">
          <div className=" mx-auto max-w-screen-2xl w-full pb-10 md:pb-20 text-left py-5 lg:text-left z-20">
              <div className="flex flex-col lg:w-4/5 m-auto content-center text-center">
                
                <div className="flex items-center m-auto bg-gray-100 w-max h-7  px-3 rounded-lg mb-5  lg:mb-10 sm:mb-10">
                  <p className="font-normal text-sm">CONTACT</p>
                </div>
                <h1 className="text-2xl font-bold  self-center text-gray-900 max-w-xl mb-10 lg:m-0 lg:mb-7 lg:px-0 lg:max-w-2xl sm:text-3xl md:text-5xl lg:text-5xl xl:text-5xl sm:mb-10">
                <span className="block xl:inline tracking-wide m-w-md text-center">Get in touch & let's work together</span>
                </h1>
                <p className="max-w-lg mb-14 text-lg text-center self-center text-gray-600 sm:text-lg lg:mb-10">
                Got a project? Drop me a line if you want to work together on something exciting. Big or small. ML or BI.
                </p>


                  <a href="mailto:nicolaiherforth@gmail.com" className="self-center bg-buttonblue text-white rounded-lg px-7 py-4 transition hover:bg-blue-700">
                    Contact Me
                  </a>  
              </div>
          </div>
        </main>
      </div>
      </Fade>
      <div className="mt-14 flex flex-col sm:flex-row sm:justify-between">
        <nav className="text-gray-400 flex content-center justify-evenly sm:justify-start h-auto">
          <AnchorLink href="#aboutme" className='sm:pr-8 transition hover:text-gray-500'>About</AnchorLink>
          <a href="https://medium.com/@nicolaiherforth"className='sm:pr-8 transition hover:text-gray-500'>Blog</a>
          <a href="https://www.youtube.com/channel/UCNQNMr08i2QgUArwyUMx_BA" className="transition hover:text-gray-500">YouTube</a>
        </nav>
        <div className="flex mx-auto mt-10 mb-14 sm:mx-0 sm:mt-0">
                          {socialLinks.map(item => (
                              <a
                                  key={item.id}
                                  href={item.url}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="mr-6 text-3xl sm:text-2xl text-gray-400 transition hover:text-gray-500"
                              >
                                  {item.icon}
                              </a>
                          ))}

                          <a href="mailto:nicolaiherforth@gmail.com" className="text-3xl text-gray-400 sm:text-2xl transition hover:text-gray-500">
                              <FaEnvelope />
                          </a>
          </div>
        
      </div>
      <p className="text-gray-300 text-center">
          © 2025 Nicolai Herforth. All rights reserved.
        </p>
    </div>
  )
}

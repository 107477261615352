/* This example requires Tailwind CSS v2.0+ */

import { FiGithub, FiExternalLink, FiStar, FiGitBranch } from 'react-icons/fi';
import { formatNumber } from '../utils/formatNumber';
import { getLanguageColor, getLanguageDisplay } from '../utils/languageConfig';

const ProjectCard = ({ project }) => {
  const displayLanguage = getLanguageDisplay(project.language) || 'Other';
  const languageColor = getLanguageColor(project.language);
  
  return (
    <div className="group relative bg-white rounded-2xl shadow-lg border border-gray-100 overflow-hidden transition-all duration-500 hover:shadow-2xl hover:-translate-y-2 h-[400px]">
      {/* Language Color Bar */}
      <div 
        className="absolute top-0 left-0 right-0 h-1.5 transition-all duration-500 group-hover:h-3 group-hover:opacity-80"
        style={{ backgroundColor: languageColor }}
      />
      
      <div className="flex flex-col h-full p-6">
        {/* Top Section - Fixed height */}
        <div className="h-8">
          {/* Language Badge */}
          <span className="inline-block px-3 py-1 text-xs font-medium text-blue-600 bg-blue-50 rounded-full">
            {displayLanguage}
          </span>
        </div>

        {/* Content Section - Fixed heights */}
        <div className="pt-2">
          {/* Header - Fixed height */}
          <div className="h-8">
            <h3 className="text-xl font-semibold text-gray-900 truncate">
              {project.name.replace(/[^a-zA-Z ]/g, ' ')
                .split(' ')
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ')}
            </h3>
          </div>

          {/* Description - Fixed height */}
          <div className="h-24 mb-4">
            <p style={{ display: '-webkit-box', WebkitLineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden' }} 
               className="text-gray-600 text-sm leading-relaxed">
              {project.description || 'No description available'}
            </p>
          </div>
        </div>

        {/* Bottom Section - Fixed position */}
        <div className="mt-auto">
          {/* Status & Date */}
          <div className="flex items-center justify-between text-sm mb-4">
            <div className="flex items-center space-x-2">
              <div className="w-2 h-2 rounded-full bg-green-500" />
              <span className="text-gray-600">Live</span>
            </div>
            <span className="text-gray-400">
              {new Date(project.updated_at).toLocaleDateString('en-US', { 
                year: 'numeric',
                month: 'short'
              })}
            </span>
          </div>

          {/* Action Bar */}
          <div className="h-12 flex items-center justify-between border-t border-gray-100 pt-4">
            <a 
              href={project.html_url}
              target="_blank"
              rel="noreferrer"
              className="flex items-center space-x-2 text-gray-600 hover:text-blue-600 font-medium group/link"
            >
              <span>View Project</span>
              <FiExternalLink className="w-4 h-4" />
            </a>
            
            <a 
              href={project.html_url}
              target="_blank"
              rel="noreferrer"
              className="p-2 text-gray-400 hover:text-blue-600 transition-colors duration-300"
            >
              <FiGithub className="w-4 h-4" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;

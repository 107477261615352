/* This example requires Tailwind CSS v2.0+ */

import { useState, useContext } from "react";
import { GithubRepos } from "../App";
import { useEffect } from "react";
import Loading from "../components/Loading";
import ProjectCard from "../components/ProjectCard";
import { Fade } from "react-reveal";
import { FiGithub } from "react-icons/fi";

export default function Projects() {
  const repos = useContext(GithubRepos);
  const [latestSix, setLatestSix] = useState([]);
  const blacklisted = ['NicolaiHerforth.github.io']

  useEffect(() => {
    if(repos.length > 0) {
      setLatestSix(repos.filter(r => !blacklisted.includes(r.name)).slice(0, 6));
    }
  }, [repos]);

  if (latestSix.length <= 0) return <Loading />;
  
  return (
    <div className="relative h-max font-sans" id="projects">
      <div className="max-w-screen-2xl mx-auto px-4 md:px-8 py-16">
        <Fade left>
        <Fade left>
              <div className="flex items-center bg-blue-100 w-max h-7 px-3 rounded-lg mb-5 lg:m-0 lg:mb-10 sm:mb-10">
                <p className="font-normal text-sm text-blue-700">PROJECTS</p>
              </div>
            </Fade>
          <h1 className="text-4xl font-bold mb-6">
            <span className="block xl:inline tracking-wide bg-gradient-to-r from-blue-800 via-gray-800 to-blue-900 bg-clip-text text-transparent">
              Recent Projects
            </span>
          </h1>
          <p className="max-w-2xl text-lg text-gray-600 mb-12">
            Just my latest personal repositories, have look, go wild!
          </p>
        </Fade>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
          {latestSix.map((repo, index) => (
            <Fade bottom delay={100 * index} key={repo.id}>
              <div className="w-full max-w-md mx-auto">
                <ProjectCard project={repo} />
              </div>
            </Fade>
          ))}
        </div>

        <Fade bottom>
          <div className="flex justify-center">
            <a 
              href="https://github.com/NicolaiHerforth" 
              target="_blank" 
              rel="noopener noreferrer"
              className="inline-flex items-center px-8 py-4 rounded-xl text-white bg-blue-600 hover:bg-blue-700 transition-colors duration-200 shadow-lg hover:shadow-xl transform hover:-translate-y-0.5"
            >
              <FiGithub className="w-5 h-5 mr-2" />
              <span className="font-medium">View All Projects</span>
            </a>
          </div>
        </Fade>
      </div>
    </div>
  );
}

export const PROGRAMMING_LANGUAGES = new Set([
  'Python',
  'TypeScript',
  'JavaScript',
  'Java',
  'C++',
  'C#',
  'Go',
  'Rust',
  'Swift',
  'Kotlin',
  'Ruby',
  'PHP'
]);

export const LANGUAGE_MAP = {
  'Jupyter Notebook': 'Python',
  'TypeScript': 'TypeScript',
  'JavaScript': 'JavaScript',
  'Python': 'Python',
  'HTML': 'Web',
  'CSS': 'Web',
  'Shell': 'Shell',
  'Dockerfile': 'Docker'
};

export const LANGUAGE_COLORS = {
  'JavaScript': '#f7df1e',
  'TypeScript': '#3178c6',
  'Python': '#3776ab',
  'default': '#6b7280'
};

export const getLanguageDisplay = (language) => {
  const mappedLang = LANGUAGE_MAP[language] || language;
  return PROGRAMMING_LANGUAGES.has(mappedLang) ? mappedLang : null;
};

export const getLanguageColor = (language) => {
  return LANGUAGE_COLORS[language] || LANGUAGE_COLORS.default;
}; 